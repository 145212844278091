.react-select-container .react-select__control {
  text-align: left;
  height: 55px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: 1px solid #3b3b3b;
  border-radius: 32px;
  white-space: pre-wrap;
  padding: 0 20px;
}

.react-select-container .react-select__value-container {
  font-size: 16px;
  color: #fff;
  background-color: #000;
  white-space: pre-wrap;
}

.react-select-container .react-select__value-container .react-select__input-container {
  font-size: 16px;
  color: #fff;
  white-space: pre-wrap;
}

.react-select-container .react-select__value-container .react-select__single-value {
  font-size: 16px;
  color: #fff;
  white-space: pre-wrap;
}

.react-select-container .react-select__indicator-separator {
  display: none;
}

.react-select-container .react-select__menu {
  color: #fff;
  background-color: #000;
  border: 1px solid #3b3b3b;
}

.react-select__menu .react-select__option {
  background-color: #000;
  border: none;
  color: #fff;
}
.react-select__option:hover {
  background: #0074d9;
}
